define("discourse/plugins/swapd-close-topic/discourse/initializers/extend-for-close-topic", ["exports", "discourse/lib/plugin-api", "I18n", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _pluginApi, _I18n, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function customClose(topic) {
    let mark_sold = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    (0, _ajax.ajax)(`/t/${topic.id}/custom_close`, {
      type: "POST",
      data: {
        mark_sold
      }
    }).then(data => {
      location.reload();
    }).catch(_ajaxError.popupAjaxError);
  }
  function showModal(container, topic) {
    const dialog = container.lookup("service:dialog");
    dialog.alert({
      message: _I18n.default.t("custom_close_topic.message"),
      buttons: [{
        icon: "lock",
        label: _I18n.default.t("custom_close_topic.just_close"),
        class: "btn-default",
        action: () => customClose(topic)
      }, {
        icon: "dollar-sign",
        label: _I18n.default.t("custom_close_topic.close_mark_sold"),
        class: "btn-danger",
        action: () => customClose(topic, true)
      }, {
        label: _I18n.default.t("composer.cancel")
      }]
    });
  }
  function registerTopicFooterButton(api, container) {
    api.registerTopicFooterButton({
      id: "swapd-close-topic",
      icon: "lock",
      priority: 150,
      label: "custom_close_topic.close",
      action() {
        showModal(container, this.get("topic"));
      },
      dropdown() {
        return false;
      },
      classNames: ["swapd-close-topic"],
      dependentKeys: [],
      displayed() {
        return this.get("topic.custom_can_close_topic");
      },
      disabled() {
        return false;
      }
    });
  }
  var _default = _exports.default = {
    name: "extend-for-close-topic",
    initialize(container) {
      const siteSettings = container.lookup("site-settings:main");
      if (!siteSettings.custom_close_topic_enabled) {
        return;
      }
      (0, _pluginApi.withPluginApi)("0.8.28", api => registerTopicFooterButton(api, container));
    }
  };
});